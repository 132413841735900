@tailwind base;
@tailwind components;
@tailwind utilities;

.body {
	background-color: #ecf5f5;
	min-height: 100dvh;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.dialog {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(1, 1, 1, 0.6);
	display: flex;
	align-items: center;
	justify-content: center;
}

.dialog-win {
	z-index: 5;
	background-color: rgb(199, 218, 224);
	padding: 20px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
}

.dialog-bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.field-name {
	/* width: 270px; */
}

.field-size {
	font-size: 25px;
}

.field {
	width: 100%;
	display: flex;
}

.range {
	flex-grow: 1;
	margin-right: 10px;
}

.field-line {
	margin-top: 10px;
}

.text-display {
	width: 30px;
}

.save-button {
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.title {
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.title>div {
	font-weight: bold;
	font-size: 30px;
}

.button {
	color: #777;
}

.button>span {
	font-size: 40px;
}

.keyboard-body {
	margin: 0 10px 20px 10px;
}

.keyboard-line {
	display: flex;
	width: 100%;
	margin-top: 20px;
}

.key-button {
	margin: 0 1px;
	font-size: 30px;
	flex: 1 1 0px;
	background-color: #9fadad;
	text-align: center;
	border-radius: 5px;
	height: 70px;
}

.result-restart {
	font-size: 20px;
}

.dialog-result {
	width: 300px;
	height: 400px;
}

.game-result {
	font-size: 20px;
	text-align: center;
}

.game-word {
	font-size: 25px;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
}

.result-main {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 50px 0;
}


.card {
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 40px;
	font-weight: bold;
	border-radius: 5px;
	text-transform: uppercase;

	background-color: #9fadad;
	color: black;
}

.card-half {
	background-color: #e9e200;
	color: black;
}

.card-correct {
	background-color: #24ed00;
	color: white;
}

.card-wrong {
	background-color: #e3e3e3;
	color: gray;
}

.flip-card {
	background-color: transparent;
	width: 50px;
	height: 50px;
	perspective: 100px;
}

.flip-card-inner {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	transition: transform .5s ease-in-out;
	transform-style: preserve-3d;
}

.flip-card-hover .flip-card-inner {
	transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.flip-card-front {}

.flip-card-back {
	transform: rotateY(180deg);
}

.key-button-half {
	background-color: #e9e200;
	color: black;
}

.key-button-correct {
	background-color: #24ed00;
	color: white;
}

.key-button-wrong {
	background-color: #e3e3e3;
	color: gray;
}